// General Icons
import AlertCircle from './alert-circle.svg';
import AlertSquare from './alert-square.svg';
import AlertTriangle from './alert-triangle.svg';
import Archive from './archive.svg';
import ArrowUpRight from './arrow-up-right.svg';
import BrowserPage from './browser-page.svg';
import Building from './building.svg';
import Check from './check.svg';
import CheckboxChecked from './checkbox-checked.svg';
import CheckboxDone from './checkbox-done.svg';
import CheckboxIndeterminate from './checkbox-indeterminate.svg';
import CheckboxUnchecked from './checkbox-unchecked.svg';
import CheckCircle from './check-circle.svg';
import ChevronDown from './chevron-down.svg';
import ChevronUp from './chevron-up.svg';
import ChevronUpDouble from './chevron-up-double.svg';
import ChevronRight from './chevron-right.svg';
import ChevronLeft from './chevron-left.svg';
import ClockSnoozed from './clock-snoozed.svg';
import Close from './x-close.svg';
import CodeBrowser from './code-browser.svg';
import CodeSquare from './code-square.svg';
import ComingSoon from './coming-soon.svg';
import Copy from './copy.svg';
import CryptoKey from './crypto-key.svg';
import Cube from './cube.svg';
import CutCircleYellow from './cut-circle-yellow.svg';
import Dotpoints from './dotpoints.svg';
import DotsHorizontal from './dots-horizontal.svg';
import Download from './download.svg';
import EditUser from './edit-user.svg';
import Edit from './edit.svg';
import Fingerprint from './fingerprint.svg';
import HighSeverity from './high-severity.svg';
import Human from './human.svg';
import HumanWithNHI from './human-nhi-instances.svg';
import Info from './info.svg';
import InfoSeverity from './info-severity.svg';
import LegoBlock from './lego-block.svg';
import LogOut from './log-out.svg';
import LowSeverity from './low-severity.svg';
import Manage from './manage.svg';
import MediumSeverity from './medium-severity.svg';
import Natoma from './natoma-logo-light.svg';
import NonHuman from './non-human.svg';
import OfficeBuilding from './office-building.svg';
import OpenCircle from './open-circle.svg';
import OpenInNew from './open-in-new.svg';
import Paperclip from './paperclip.svg';
import Plus from './plus.svg';
import PermissionSet from './permission-set.svg';
import PuzzlePiece from './puzzle-piece.svg';
import Rules from './rules.svg';
import Search from './search.svg';
import Settings from './settings.svg';
import StopCircle from './stop-circle.svg';
import StopCircleRed from './stop-circle-red.svg';
import Trash from './trash.svg';
import Unknown from './unknown.svg';
import UnknownIntegration from './unknown-integration.svg';
import UserX from './user-x.svg';
import User from './user.svg';
import UserEdit from './user-edit.svg';
import UserSquare from './user-square.svg';
import Webhook from './webhook.svg';

// Authenticator Icons
import AccessKey from './access-key.svg';
import APIToken from './api-token.svg';
import Certificate from './certificate.svg';
import ClientSecret from './client-secret.svg';
import KeyPair from './key-pair.svg';
import OAuthGrant from './oauth-grant.svg';
import Password from './password.svg';
import PrivateAccessToken from './pat.svg';

// Scope/permission icons
import ScopeAppRead from './scope-app-read.svg';
import ScopeAppWrite from './scope-app-write.svg';
import ScopeCredRead from './scope-cred-read.svg';
import ScopeCredWrite from './scope-cred-write.svg';
import ScopeGroupRead from './scope-group-read.svg';
import ScopeGroupWrite from './scope-group-write.svg';
import ScopeLogsRead from './scope-logs-read.svg';
import ScopeLogsWrite from './scope-logs-write.svg';
import ScopeMailRead from './scope-mail-read.svg';
import ScopeMailWrite from './scope-mail-write.svg';
import ScopeOrgRead from './scope-org-read.svg';
import ScopeOrgWrite from './scope-org-write.svg';
import ScopePolicyRead from './scope-policy-read.svg';
import ScopePolicyWrite from './scope-policy-write.svg';
import ScopePrivilegeRead from './scope-privilege-read.svg';
import ScopePrivilegeWrite from './scope-privilege-write.svg';
import ScopeProfileRead from './scope-profile-read.svg';
import ScopeProfileWrite from './scope-profile-write.svg';
import ScopeTicketRead from './scope-ticket-read.svg';
import ScopeTicketWrite from './scope-ticket-write.svg';
import ScopeUserRead from './scope-user-read.svg';
import ScopeUserWrite from './scope-user-write.svg';
import ShareLink from './share-link.svg';

// Integration Icons
import AWS from './integrations/aws.svg';
import AzureAD from './integrations/azure-ad.svg';
import Entra from './integrations/entra.svg';
import GCP from './integrations/gcp.svg';
import GitHub from './integrations/github.svg';
import GoogleWorkspace from './integrations/google.svg';
import Jira from './integrations/jira.svg';
import Okta from './integrations/okta.svg';
import Salesforce from './integrations/salesforce.svg';
import Slack from './integrations/slack.svg';
import Snowflake from './integrations/snowflake.svg';

// Activity Type Icons - these are 12x12 base svgs;

import SmallCode from './small-code.svg';
import SmallCursorClick from './small-cursor-click.svg';

/**
 * Natomicon Icon Properties. Height and width must both be provided to take effect.
 */
export type IconProps = {
  height?: number;
  width?: number;
  size?: 'small' | 'medium' | 'large' | 'xlarge';
  color?: string;
  ariaLabel?: string;
};

/**
 * Icon sizes in pixels.
 */
const ICON_SIZES = {
  small: 12,
  medium: 16,
  large: 20,
  xlarge: 24
};

/**
 * Default icon size.
 */
const DEFAULT_ICON_SIZE = 'medium';

const formatProps = ({
  height,
  width,
  size,
  color,
  ariaLabel
}: IconProps): {
  height: number;
  width: number;
  color?: string;
} => {
  const otherProps = {
    ...(color && { color: color }),
    ...(ariaLabel && { 'aria-label': ariaLabel })
  };
  if (height && width) {
    return {
      height: height ?? ICON_SIZES[DEFAULT_ICON_SIZE],
      width: width ?? ICON_SIZES[DEFAULT_ICON_SIZE],
      ...otherProps
    };
  } else if (size) {
    return {
      height: ICON_SIZES[size],
      width: ICON_SIZES[size],
      ...otherProps
    };
  } else {
    return {
      height: ICON_SIZES[DEFAULT_ICON_SIZE],
      width: ICON_SIZES[DEFAULT_ICON_SIZE],
      ...otherProps
    };
  }
};

export function AccessKeyIcon(props: IconProps) {
  return <AccessKey {...formatProps(props)} />;
}

export function AlertCircleIcon(props: IconProps) {
  return <AlertCircle {...formatProps(props)} />;
}

export function AlertSquareIcon(props: IconProps) {
  return <AlertSquare {...formatProps(props)} />;
}

export function AlertTriangleIcon(props: IconProps) {
  return <AlertTriangle {...formatProps(props)} />;
}

export function APITokenIcon(props: IconProps) {
  return <APIToken {...formatProps(props)} />;
}

export function ArchiveIcon(props: IconProps) {
  return <Archive {...formatProps(props)} />;
}

export function ArrowUpRightIcon(props: IconProps) {
  return <ArrowUpRight {...formatProps(props)} />;
}

export function BrowserPageIcon(props: IconProps) {
  return <BrowserPage {...formatProps(props)} />;
}

export function BuildingIcon(props: IconProps) {
  return <Building {...formatProps(props)} />;
}

export function CertificateIcon(props: IconProps) {
  return <Certificate {...formatProps(props)} />;
}

export function CheckIcon(props: IconProps) {
  return <Check {...formatProps(props)} />;
}

export function CheckboxCheckedIcon(props: IconProps) {
  return <CheckboxChecked {...formatProps(props)} />;
}

export function CheckboxDoneIcon(props: IconProps) {
  return <CheckboxDone {...formatProps(props)} />;
}

export function CheckboxIndeterminateIcon(props: IconProps) {
  return <CheckboxIndeterminate {...formatProps(props)} />;
}

export function CheckboxUncheckedIcon(props: IconProps) {
  return <CheckboxUnchecked {...formatProps(props)} />;
}

export function CheckCircleIcon(props: IconProps) {
  return <CheckCircle {...formatProps(props)} />;
}

export function ChevronDownIcon(props: IconProps) {
  return <ChevronDown {...formatProps(props)} />;
}

export function ChevronUpIcon(props: IconProps) {
  return <ChevronUp {...formatProps(props)} />;
}

export function ChevronUpDoubleIcon(props: IconProps) {
  return <ChevronUpDouble {...formatProps(props)} />;
}

export function ChevronRightIcon(props: IconProps) {
  return <ChevronRight {...formatProps(props)} />;
}

export function ChevronLeftIcon(props: IconProps) {
  return <ChevronLeft {...formatProps(props)} />;
}

export function ClientSecretIcon(props: IconProps) {
  return <ClientSecret {...formatProps(props)} />;
}

export function ClockSnoozedIcon(props: IconProps) {
  return <ClockSnoozed {...formatProps(props)} />;
}

export function CloseIcon(props: IconProps) {
  return <Close {...formatProps(props)} />;
}

export function CodeBrowserIcon(props: IconProps) {
  return <CodeBrowser {...formatProps(props)} />;
}

export function CodeSquareIcon(props: IconProps) {
  return <CodeSquare {...formatProps(props)} />;
}

export function ComingSoonBackground(props: IconProps) {
  return <ComingSoon {...formatProps(props)} />;
}

export function CopyIcon(props: IconProps) {
  return <Copy {...formatProps(props)} />;
}

export function CryptoKeyIcon(props: IconProps) {
  return <CryptoKey {...formatProps(props)} />;
}

export function CubeIcon(props: IconProps) {
  return <Cube {...formatProps(props)} />;
}

export function CutCircleYellowIcon(props: IconProps) {
  return <CutCircleYellow {...formatProps(props)} />;
}

export function DotpointsIcon(props: IconProps) {
  return <Dotpoints {...formatProps(props)} />;
}

export function DotsHorizontalIcon(props: IconProps) {
  return <DotsHorizontal {...formatProps(props)} />;
}

export function DownloadIcon(props: IconProps) {
  return <Download {...formatProps(props)} />;
}

export function EditUserIcon(props: IconProps) {
  return <EditUser {...formatProps(props)} />;
}

export function EditIcon(props: IconProps) {
  return <Edit {...formatProps(props)} />;
}

export function FingerprintIcon(props: IconProps) {
  return <Fingerprint {...formatProps(props)} />;
}

export function HighSeverityIcon(props: IconProps) {
  return <HighSeverity {...formatProps(props)} />;
}

export function HumanIcon(props: IconProps) {
  return <Human {...formatProps(props)} />;
}

export function HumanWithNHIIcon(props: IconProps) {
  return <HumanWithNHI {...formatProps(props)} />;
}

export function InfoIcon(props: IconProps) {
  return <Info {...formatProps(props)} />;
}

export function InfoSeverityIcon(props: IconProps) {
  return <InfoSeverity {...formatProps(props)} />;
}

export function KeyPairIcon(props: IconProps) {
  return <KeyPair {...formatProps(props)} />;
}

export function LegoBlockIcon(props: IconProps) {
  return <LegoBlock {...formatProps(props)} />;
}

export function LogOutIcon(props: IconProps) {
  return <LogOut {...formatProps(props)} />;
}

export function LowSeverityIcon(props: IconProps) {
  return <LowSeverity {...formatProps(props)} />;
}

export function ManageIcon(props: IconProps) {
  return <Manage {...formatProps(props)} />;
}

export function MediumSeverityIcon(props: IconProps) {
  return <MediumSeverity {...formatProps(props)} />;
}

export function NatomaIcon(props: IconProps) {
  return <Natoma {...formatProps(props)} />;
}

export function NonHumanIcon(props: IconProps) {
  return <NonHuman {...formatProps(props)} />;
}

export function OAuthGrantIcon(props: IconProps) {
  return <OAuthGrant {...formatProps(props)} />;
}
export function OfficeBuildingIcon(props: IconProps) {
  return <OfficeBuilding {...formatProps(props)} />;
}

export function OpenCircleIcon(props: IconProps) {
  return <OpenCircle {...formatProps(props)} />;
}

export function OpenInNewIcon(props: IconProps) {
  return <OpenInNew {...formatProps(props)} />;
}

export function PaperclipIcon(props: IconProps) {
  return <Paperclip {...formatProps(props)} />;
}

export function PasswordIcon(props: IconProps) {
  return <Password {...formatProps(props)} />;
}

export function PermissionSetIcon(props: IconProps) {
  return <PermissionSet {...formatProps(props)} />;
}

export function PlusIcon(props: IconProps) {
  return <Plus {...formatProps(props)} />;
}

export function PrivateAccessKeyIcon(props: IconProps) {
  return <PrivateAccessToken {...formatProps(props)} />;
}

export function PuzzlePieceIcon(props: IconProps) {
  return <PuzzlePiece {...formatProps(props)} />;
}

export function RulesIcon(props: IconProps) {
  return <Rules {...formatProps(props)} />;
}

export function ScopeAppReadIcon(props: IconProps) {
  return <ScopeAppRead {...formatProps(props)} />;
}

export function ScopeAppWriteIcon(props: IconProps) {
  return <ScopeAppWrite {...formatProps(props)} />;
}

export function ScopeCredReadIcon(props: IconProps) {
  return <ScopeCredRead {...formatProps(props)} />;
}

export function ScopeCredWriteIcon(props: IconProps) {
  return <ScopeCredWrite {...formatProps(props)} />;
}

export function ScopeGroupReadIcon(props: IconProps) {
  return <ScopeGroupRead {...formatProps(props)} />;
}

export function ScopeGroupWriteIcon(props: IconProps) {
  return <ScopeGroupWrite {...formatProps(props)} />;
}

export function ScopeLogsReadIcon(props: IconProps) {
  return <ScopeLogsRead {...formatProps(props)} />;
}

export function ScopeLogsWriteIcon(props: IconProps) {
  return <ScopeLogsWrite {...formatProps(props)} />;
}

export function ScopeMailReadIcon(props: IconProps) {
  return <ScopeMailRead {...formatProps(props)} />;
}

export function ScopeMailWriteIcon(props: IconProps) {
  return <ScopeMailWrite {...formatProps(props)} />;
}

export function ScopeOrgReadIcon(props: IconProps) {
  return <ScopeOrgRead {...formatProps(props)} />;
}

export function ScopeOrgWriteIcon(props: IconProps) {
  return <ScopeOrgWrite {...formatProps(props)} />;
}

export function ScopePolicyReadIcon(props: IconProps) {
  return <ScopePolicyRead {...formatProps(props)} />;
}

export function ScopePolicyWriteIcon(props: IconProps) {
  return <ScopePolicyWrite {...formatProps(props)} />;
}

export function ScopePrivilegeReadIcon(props: IconProps) {
  return <ScopePrivilegeRead {...formatProps(props)} />;
}

export function ScopePrivilegeWriteIcon(props: IconProps) {
  return <ScopePrivilegeWrite {...formatProps(props)} />;
}

export function ScopeProfileReadIcon(props: IconProps) {
  return <ScopeProfileRead {...formatProps(props)} />;
}

export function ScopeProfileWriteIcon(props: IconProps) {
  return <ScopeProfileWrite {...formatProps(props)} />;
}

export function ScopeTicketReadIcon(props: IconProps) {
  return <ScopeTicketRead {...formatProps(props)} />;
}

export function ScopeTicketWriteIcon(props: IconProps) {
  return <ScopeTicketWrite {...formatProps(props)} />;
}

export function ScopeUserReadIcon(props: IconProps) {
  return <ScopeUserRead {...formatProps(props)} />;
}

export function ScopeUserWriteIcon(props: IconProps) {
  return <ScopeUserWrite {...formatProps(props)} />;
}

export function ShareLinkIcon(props: IconProps) {
  return <ShareLink {...formatProps(props)} />;
}

export function SearchIcon(props: IconProps) {
  return <Search {...formatProps(props)} />;
}

export function SettingsIcon(props: IconProps) {
  return <Settings {...formatProps(props)} />;
}

export function StopCircleIcon(props: IconProps) {
  return <StopCircle {...formatProps(props)} />;
}

export function StopCircleRedIcon(props: IconProps) {
  return <StopCircleRed {...formatProps(props)} />;
}

export function TrashIcon(props: IconProps) {
  return <Trash {...formatProps(props)} />;
}

export function UnknownIcon(props: IconProps) {
  return <Unknown {...formatProps(props)} />;
}

export function UnknownIntegrationIcon(props: IconProps) {
  return <UnknownIntegration {...formatProps(props)} />;
}

export function UserXIcon(props: IconProps) {
  return <UserX {...formatProps(props)} />;
}

export function UserIcon(props: IconProps) {
  return <User {...formatProps(props)} />;
}

export function UserEditIcon(props: IconProps) {
  return <UserEdit {...formatProps(props)} />;
}

export function UserSquareIcon(props: IconProps) {
  return <UserSquare {...formatProps(props)} />;
}

export function WebhookIcon(props: IconProps) {
  return <Webhook {...formatProps(props)} />;
}

/** Integration Icons */
export function AWSIcon(props: IconProps) {
  return <AWS {...formatProps(props)} />;
}

export function AzureADIcon(props: IconProps) {
  return <AzureAD {...formatProps(props)} />;
}

export function EntraIcon(props: IconProps) {
  return <Entra {...formatProps(props)} />;
}

export function GCPIcon(props: IconProps) {
  return <GCP {...formatProps(props)} />;
}

export function GitHubIcon(props: IconProps) {
  return <GitHub {...formatProps(props)} />;
}

export function GoogleWorkspaceIcon(props: IconProps) {
  return <GoogleWorkspace {...formatProps(props)} />;
}

export function JiraIcon(props: IconProps) {
  return <Jira {...formatProps(props)} />;
}

export function OktaIcon(props: IconProps) {
  return <Okta {...formatProps(props)} />;
}

export function SalesforceIcon(props: IconProps) {
  return <Salesforce {...formatProps(props)} />;
}

export function SlackIcon(props: IconProps) {
  return <Slack {...formatProps(props)} />;
}

export function SnowflakeIcon(props: IconProps) {
  return <Snowflake {...formatProps(props)} />;
}

export function SmallCodeIcon(props: IconProps) {
  return <SmallCode {...formatProps({ ...props, size: 'small' })} />;
}

export function SmallCursorClickIcon(props: IconProps) {
  return <SmallCursorClick {...formatProps({ ...props, size: 'small' })} />;
}
